<template>
    <div class="goods-info-content">
        <div class="theory-list-header" style="padding-top: 20px">
            状态
            <el-select v-model="stateValue" style="width: 200px;margin-left: 10px" placeholder="请选择" @change="selectStates">
                <el-option
                        v-for="item in stateList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
        <el-table :data="studentSubmitList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%">
<!--            <el-table-column prop="nickname" label="学生名字"></el-table-column>
            <el-table-column prop="eaxm_name" label="考试名称"></el-table-column>-->
            <el-table-column label="考试模块" align="center">
                <template slot-scope="scope">
                    <span>社交电商（{{scope.row.social_type}}）</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="exam_time" label="考试时间"></el-table-column>
            <el-table-column :label="`评分（最高分数为${high_score}分）`" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.status === '未提交'">该学生未提交暂不能评分</span>
                    <div v-else>
                        <el-input-number
                                v-model="scope.row.mark_score"
                                :min="0"
                                :max="high_score"
                                :controls="false"
                                :precision="0"
                                style="width: 88px;">
                        </el-input-number>
                        <span v-if="scope.row.status === '待批改'" @click="submitScore(scope.row)" class="submit-score">确定</span>
                        <span v-if="scope.row.status === '已批改'" @click="submitReScore(scope.row)" class="submit-score">重新提交</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="success" :underline="false"
                                 @click="viewSocial(scope.row)"
                                 v-if="scope.row.status !== '未提交'"
                                 class="view-detail">
                            查看详情
                        </el-link>
                        <el-link type="success" :underline="false" @click="viewGrading(scope.row)" class="view-detail">评分标准</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="studentSubmitPages.currentPageNum"
                       :page-size="studentSubmitPages.eachPageNum"
                       :total="studentSubmitPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="studentSubmitCurrentChange">
        </el-pagination>
        <el-dialog title="查看详情" :visible.sync="SocialDialog" width="764px" @close="handleCloseSocialDialog" custom-class="green-dialog goods-img-dialog">
            <div class="article-wrap">
                <el-scrollbar class="content">
                    <div class="top-title">{{articleInfo.title}}</div>
                    <div class="top-author">{{articleInfo.author}}</div>
                    <div class="top-author">平台:{{articleInfo.platform==1?'微博':'公众号'}}</div>
                    <div class="top-content" v-html="articleInfo.content"></div>
                    <div style="width: 80%;margin: auto;">
                          <img  class="top-img" :src="articleInfo.img" />
                    </div>
                </el-scrollbar>
            </div>
        </el-dialog>
        <el-dialog title="评分标准"
                   :visible.sync="gradingDialog" width="830px" @close="handleCloseGradingDialog" custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;" height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                    <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                    <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                    <el-table-column prop="score" label="配分" align="center"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        teacheropmJudgesSocial,
        teacheropmScore,
        teacheropmReScoreSocial,
        teacheropmGetJudgesStandard,
        teacheropmGetSocialInfo
    } from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                //学生提交列表
                studentSubmitList: [],
                //商品主图弹窗
                SocialDialog: false,
                selectIndex: 0,
                //评分标准列表
                gradingList: [],
                //评分标准弹窗
                gradingDialog: false,
                //分页
                studentSubmitPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //状态列表
                stateList: [
                    {
                        value: 2,
                        label: '未批改'
                    },
                    {
                        value: 0,
                        label: '未提交'
                    },
                    {
                        value: 1,
                        label: '已批改'
                    },
                    
                ],
                stateValue: 2,
                //最高分数
                high_score: '',
                //试题id
                op_id: '',
                //查看详情
                articleInfo: {
                    title: '',
                    content: '',
                    author: '',
                    img:'',
                    platform:''
                },
            }
        },
        created() {
            this.getStudentSubmitList();
        },
        methods: {
            //切换状态
            selectStates(){
                this.studentSubmitPages.currentPageNum = 1;
                this.getStudentSubmitList();
            },
            //获取网店视觉设计列表
            getStudentSubmitList() {
                let param = {
                    exam_id: this.$route.query.examId,
                    page: this.studentSubmitPages.currentPageNum,
                    paging: 1,
                    pageSize: this.studentSubmitPages.eachPageNum
                }
                if (this.stateValue !== 0) {
                    param.status = this.stateValue;
                }
                teacheropmJudgesSocial(param).then((res) => {
                    this.studentSubmitList = res.data.list;
                    this.high_score = res.data.high_score;
                    this.op_id = res.data.list[0].op_id;
                    this.studentSubmitPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //查看详情
            viewSocial(row) {
                this.SocialDialog = true;
                this.getTeacheropmExamData(row.id);
            },
            //获取详情
            getTeacheropmExamData(id) {
                teacheropmGetSocialInfo(id).then((res) => {
                    this.articleInfo.author = res.data.author;
                    this.articleInfo.title = res.data.title;
                    this.articleInfo.content = res.data.content;
                    this.articleInfo.img=res.data.cover
                    this.articleInfo.platform=res.data.platform
                }).catch((err) => {
                    console.log(err);
                })
            },
            //关闭商品主图弹窗
            handleCloseSocialDialog() {
                this.SocialDialog = false;
                this.selectIndex = 0;
            },
            //查看评分标准
            viewGrading() {
                this.gradingDialog = true;
                this.getStandardList();
            },
            //获取评分标准列表
            getStandardList() {
                let param = {
                    op_id: this.op_id
                }
                teacheropmGetJudgesStandard(param).then((res) => {
                    this.gradingList = res.data.scoring_criteria;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //关闭评分标准弹窗
            handleCloseGradingDialog() {
                this.gradingDialog = false;
            },
            //分页
            studentSubmitCurrentChange(val) {
                this.studentSubmitPages.currentPageNum = val;
                this.getStudentSubmitList();
            },
            //提交分数
            submitScore(row) {
                let param = {
                    id: row.id,
                    score: row.mark_score
                }
                teacheropmScore(param).then((res) => {
                    this.$message.success('提交成功');
                    this.getStudentSubmitList();
                }).catch((err) => {
                    console.log(err);
                })
            },
            //重新提交分数
            submitReScore(row) {
                let param = {
                    id: row.id,
                    score: row.mark_score
                }
                teacheropmReScoreSocial(param).then((res) => {
                    this.$message.success('重新提交成功');
                    this.getStudentSubmitList();
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-info-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .link-box {
            .view-detail {
                ::v-deep .el-link--inner {
                    color: #1AB163;
                }
            }
        }
        .goods-img-dialog {
            .article-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 550px;
                .content {
                   text-align: center;
                    height: 100%;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                        .el-scrollbar__view {
                            height: 100%;
                        }
                    }
                }
                .top-title {
                    text-align: center;
                    font-size: 20px;
                    color: #333333;
                }

                .top-author {
                    margin-top: 12px;
                    text-align: center;
                    font-size: 14px;
                    color: #666666;
                }

                .top-content {
                    margin-top: 20px;

                    ::v-deep p {
                        img {
                            max-width: 900px;
                        }
                    }
                }
                .top-img{
                    margin-top: 40px;
                    position: relative;
                    // left: 25%;
                }
            }
            .upload-file-content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 445px;
            }
        }
    }
    .theory-list-header{
        display: flex;
        align-items: center;
        span{
            line-height: 1;
        }
    }
    .submit-score {
        margin-left: 10px;
        color: #FF9450;
        font-size: 16px;
        cursor: pointer;
    }
</style>